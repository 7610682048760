module.exports = {
  "中文": "Chinese",
  "英语": "English",
  "韩语": "Korean",
  "成功": "success",
  "失败": "fail",
  "确认": "confirm",
  "取消": "cancel",
  "确定": "determine",
  "余额不足": "Sorry, your credit is running low",
  "没有更多了": "There's nothing more",
  "签名失败": "Signature failed",
  "详情": "details",
  "记录": "record",
  "复制成功": "Copied successfully",
  "首页": "home",
  "算力质押": "Pledge of computing power",
  "兑换专区": "exchange",
  "全球矿场": "Global mining sites",
  "邀请好友": "Invite friends",
  "市场动态": "Market dynamics",
  "商品详情": "Product Details",
  "产品详情": "product details ",
  "立即购买": "Buy Now",
  "公告列表": "Announcement List",
  "支付方式": "Payment method",
  "质押数量": "Pledge quantity",
  "请输入质押数量": "Please enter the pledged quantity",
  "需支付": "Payment required",
  "USDT余额": "USDT balance",
  "质押记录": "Pledge record",
  "支付金额": "Payment amount",
  "XAU总算力": "XAU's ultimate strength",
  "XAU已释放算力": "XAU has released computing power",
  "质押时间": "Pledge time",
  "释放中": "Releasing in progress",
  "已结束": "Ended",
  "获取算力质押挖矿": "Acquiring computing power for staking mining",
  "当前算力倍数": "Current multiple of computing power",
  "累计产出": "Cumulative output",
  "个人算力": "Personal computing power",
  "布道产出": "Preaching output",
  "选择矿场": "Choose a mining site",
  "挖矿": "receive",
  "此矿场已选择": "This mine has been selected",
  "请选择矿场": "Please select a mining site",
  "确认订单": "Confirm order",
  "默认": "default",
  "请添加收货地址": "Please add shipping address",
  "提交订单": "place order",
  "请输入交易密码": "Please enter the transaction password",
  "链游": "Chain Game",
  "资产": "assets",
  "人民币": "RMB",
  "充值": "Recharge",
  "提现": "Withdrawal",
  "链上提取": "On chain extraction",
  "兑换": "exchange",
  "手续费": "Service Charge",
  "请输入兑换数量": "Please enter the exchange quantity",
  "当前账户": "my address",
  "请输入充值数量": "Please enter the recharge amount",
  "充值记录": "Recharge Record",
  "地址": "address",
  "充值数量": "Recharge quantity",
  "到账中": "Received in the account",
  "已到账": "Already received",
  "到账失败": "Payment failed",
  "失败原因": "Reason for failure",
  "时间": "time",
  "互转": "Mutual conversion",
  "转入地址": "Transfer to address",
  "请输入转入地址": "Please enter the transfer address",
  "USDT最小转账数量": "Minimum transfer quantity for USDT",
  "XAU最小转账数量": "Minimum transfer quantity for XAU",
  "请输入互转数量": "Please enter the number of mutual transfers",
  "转入": "to change into",
  "钱包": "wallet",
  "互转数量": "Quantity of mutual conversion",
  "实际支付": "Actual payment",
  "钱包明细": "Wallet details",
  "提现地址": "Withdrawal address",
  "请输入提现地址": "Please enter the withdrawal address",
  "提现时间": "Withdrawal time",
  "USDT最小提现数量": "Minimum withdrawal amount for USDT",
  "请输入提现数量": "Please enter the withdrawal amount",
  "金额": "amount of money",
  "实际到账": "Actual Receipt",
  "待审核": "Pending review",
  "已通过": "Already passed",
  "已驳回": "Rejected",
  "我的": "my",
  "级别": "level",
  "我的订单": "My order",
  "待付款": "Pending payment",
  "待发货": "Pending shipment",
  "待收货": "Pending receipt of goods",
  "待寄售": "To be consigned for sale",
  "全部订单": "All orders",
  "市场管理": "My team",
  "收货地址": "Receiving address",
  "钱包转账": "wallet",
  "加密社交": "Encrypting social media",
  "我的节点": "My node",
  "交易中心": "Trading Center",
  "编辑收货地址": "Edit shipping address",
  "请选择": "Please choose",
  "修改成功": "Modified successfully",
  "添加成功": "Added successfully",
  "删除成功": "Delete successfully",
  "订单详情": "Order details",
  "商家正在打包中，请耐心等待": "The merchant is currently in the process of packaging, please be patient and wait",
  "您的快递正在飞奔向您，请耐心等待": "Your package is flying towards you, please be patient and wait",
  "已完成": "Completed",
  "订单已完成，期待下次下单": "The order has been completed, looking forward to placing the next order",
  "订单编号": "Order Number",
  "下单时间": "Order time ",
  "付款时间": "Payment Time",
  "发货时间": "Delivery time",
  "快递公司": "Courier Services Company",
  "快递单号": "courier number",
  "完成时间": "Completion time",
  "数量": "quantity",
  "商品金额": "Product amount",
  "确认收货": "Confirm receipt of goods",
  "收货中": "Receiving goods",
  "收货成功": "Received goods successfully",
  "全部": "whole",
  "邀请码": "Invitation code",
  "复制并保存": "Copy and save",
  "分享人数": "Number of direct push personnel",
  "市场人数": "Team size",
  "个人业绩": "Personal performance",
  "市场业绩": "Team performance",
  "小市场业绩": "Small market performance",
  "分享列表": "Direct push list",
  "钱包地址": "Wallet address",
  "提交成功": "Submitted successfully",
  "互转记录": "Mutual conversion record",
  "提现记录": "Withdrawal record",
  "充值记录": "Recharge record",
  "请切换OKT链": "Switch to OKT chain",
  "检测到您已添加OKT Chain，是否切换到该网络？": "Detected that you have added OKT Chain, do you want to switch to this network?",
  "体验矿机": "Experience mining machine",
  "登录成功": "Login successful",
  "敬请期待": "Coming soon",
  "正在开发中": "Under development",
  "选择数量": "Select quantity",
  "库存": "stock",
  "算力的": "Computing power",
  "AI智能短剧": "AI intelligent short drama",
  "ROSELLE交易所": "ROSELLE Exchange",
  "联合资产": "Joint Assets",
  "XAU链游": "XAU Chain Game",
  "加密社交": "Encrypting social media",
  "元宇宙WEB3商城": "Metaverse WEB3 Mall",
  "公司简介": "Company profile",
  "客服": "Customer service",
  "达成条件": "Meet the conditions",
  "个人业绩累计": "Accumulated personal performance",
  "团队业绩累计": "Accumulated team performance",
  "团队伞下节点数": "Number of nodes under the team umbrella",
  "当前级别": "Current level",
  "节点": "node",
  "超级节点": "Super Node",
  "选择级别": "Select level",
  "选择区域": "Select Region",
  "申请": "apply",
  "驳回原因": "Reason for rejection",
  "审核时间": "Review time",
  "请选择级别": "Please select a level",
  "请选择区域": "Please select region",
  "OKT最小转账数量": "Minimum transfer amount for OKT",
  "授权登录": "Authorized login",
  "请敬请期待商城上线": "Please come back later",
}