<template>
  <div class='indexbox'>
    <nav-bar left-arrow :title="$t('充值')" :right-text="$t('记录')" @click-right="router('rechargelist')" />
    <div class="box">
      <div class="addbox">
        <div>
          <p>USDT</p>
          <p>{{ info.balance }}U</p>
        </div>
        <div>
          <p>OKT</p>
          <p>{{ info.okt || 0 }}</p>
        </div>
      </div>
      <div class="add" @click="copyData(address)">
        <text-p class="t">当前账户</text-p>
        <p>{{ myaddress }}</p>
      </div>
      <div class="tab">
        <div :class="{ act: type == 1 }" @click="type = 1">USDT</div>
        <div :class="{ act: type == 2 }" @click="type = 2">OKT</div>
      </div>
      <van-field v-model="number" type="number" :placeholder="$t('请输入充值数量')" clearable :border="false" />
      <van-button round block :loading="bloading" @click="submit">{{ $t('确定') }}</van-button>
    </div>
    <div class="tit">
      <span class="heng"></span><text-span>充值</text-span><text-span>记录</text-span>
    </div>

    <div class="item" v-for="val in list" :key="val.id">
      <div class="l">
        <p>{{ val.amount }}
          <template v-if="val.type == 1">USDT</template>
          <template v-if="val.type == 2">OKT</template>
        </p>
        <p>{{ val.create_time }}</p>
      </div>
      <div class="n">
        <text-p v-if="val.status == 1">到账中</text-p>
        <text-p v-if="val.status == 2">已到账</text-p>
        <text-p v-if="val.status == 3">到账失败</text-p>
      </div>
    </div>
    <text-p class="nodata" v-if="!list.length">没有更多了</text-p>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        info: {},

        myaddress: '',
        address: '',
        number: '',
        type: 1,//1 USDT  2 OKT
        bloading: false,

        list: [],
      }
    },
    created() {
      this.getdata()
      this.getinfo()
      this.onLoad()
    },
    methods: {
      getinfo() {
        this.$get({
          loading: true,
          url: "/app/member/memberInfo",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        });
      },
      getdata() {
        this.$get({
          loading: true,
          url: "/app/recharge/getAddress",
          success: (res) => {
            this.myaddress = this.$store.state.wallet.account;
            this.address = res.data.address
          },
          tip: () => { },
        });
      },
      onLoad() {
        this.$get({
          url: "/app/recharge/pageList",
          data: {
            page: 1,
          },
          success: (res) => {
            this.list = res.data.data
          },
          tip: () => { },
        })
      },
      submit() {
        this.$toast(this.$t('请敬请期待商城上线'))
        return

        if (!this.number) return this.$toast(this.$t('请输入充值数量'))
        this.bloading = true
        this.$post({
          loading: true,
          url: "/app/recharge/rechargeDo",
          data: { amount: this.number, type: this.type },
          success: (res) => {
            this.transfer(res.data.id)
          },
          tip: () => {
            this.bloading = false
            this.number = ''
          },
        });
      },
      transfer(id) {
        let that = this
        that.$toast.loading({
          forbidClick: true,
          duration: 0,
        })
        let name = ''
        let precision = '';
        let isMain = false

        if (that.type == 1) {
          name = 'usdt'
          // 测试U 精度6 mwei
          // 正式U 精度18 ether
          precision = 'mwei'
          // chain ='ether'
          isMain = false
        } else {
          name = ''
          precision = 'ether'
          isMain = true
        }
        that.$nextTick(() => {
          that.blockFun
            .transfer(name, that.number, that.address, precision, isMain)
            .then((hash) => {
              that.pay_invest(id, hash);
            }).catch((err) => {
              console.warn('usdt_err', err);
              this.$toast.fail(this.$t(err.msg))
              that.bloading = false
            });
        });
      },
      pay_invest(id, hash) {
        this.$post({
          url: '/app/recharge/rechargeSuccess',
          loading: true,
          data: {
            id,
            hash,
          },
          success: res => {
            this.number = ''
            this.bloading = false
            this.$toast({
              message: this.$t('提交成功'),
              onClose: () => {
                this.getinfo()
                this.onLoad()
              }
            })
          },
          tip: () => {
            this.number = ''
            setTimeout(() => {
              this.bloading = false
            }, 1200);
          }
        })
      }
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    padding: 10px 15px;
    .box {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
      padding: 20px 15px;
      .addbox {
        width: 100%;
        background: linear-gradient(135deg, rgba(115, 163, 255, .5) 0%, rgba(140, 38, 255, .5) 100%);
        border-radius: 8px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
        >div {
          width: 45%;
          padding: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p {
            font-size: 13px;
            &:nth-child(2) {
              font-size: 16px;
              font-weight: 550;
            }
          }
        }
      }
      .add {
        width: 100%;
        background: linear-gradient(135deg, rgba(115, 163, 255, .5) 0%, rgba(140, 38, 255, .5) 100%);
        border-radius: 8px;
        padding: 10px;
        font-size: 14px;
        .t {
          margin-bottom: 10px;
          color: #D1B6FF;
        }
        p {
          color: #fff;
          font-size: 16px;
          font-weight: 550;
        }
      }
      .tab {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #fff;
        font-size: 18px;
        padding: 20px 0;
        >div {
          width: 30%;
          text-align: center;
          padding: 6px;
          border-radius: 8px;
          border: 1px solid #EDE5FF;
          margin-right: 15px;
        }
        .act {
          background: url('../../../assets/img/o.png') no-repeat;
          background-size: 30px 30px;
          background-position: bottom right;
          color: #A874FF;
          border-color: #A874FF;
        }
      }
      :deep(.van-field) {
        background: none;
        border-radius: 8px;
        border: 1px solid #7F4AF4;
        margin-bottom: 30px;
      }
    }
    .tit {
      width: 100%;
      padding: 15px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .heng {
        width: 3px;
        height: 15px;
        background: linear-gradient(135deg, #73A3FF 0%, #8C26FF 100%);
        border-radius: 2px;
        margin-right: 8px;
      }
      span {
        font-size: 17px;
      }
    }
    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
      margin-bottom: 15px;
      padding: 10px 15px;
      .l {
        width: 80%;
        p {
          width: 100%;
          font-size: 16px;
          font-weight: 550;
          &:nth-child(2) {
            font-size: 13px;
            margin-top: 3px;
            font-weight: 500;
          }
        }
      }
      .n {
        width: 20%;
        text-align: right;
        color: #E760FF;
        font-size: 16px;
        font-weight: 550;
      }
    }
    .nodata {
      width: 100%;
      text-align: center;
    }
  }
</style>